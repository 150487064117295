import tools from '../../../libs/tools';
import { required } from '@vuelidate/validators'

const model = {
    id: 0,
    slug: "",
    first_name: "",
    last_name: "",
    translations: {}
};

const translation = {
    slug: "",
    expert_id: 0,
    locale: "",
    img: "",
    txt_desc: "",
    html_desc: "",
    guest_desc: "",
    publish_state: "",
    pub_at: null,
    books: []
};

export default {
    ...tools.models.apiCommons(model, translation, "expert_id"),
    modelValidation: {
        model: {
            slug: {
                required,
                slug: tools.models.validators.SLUG
            },
            first_name: {
                required
            }

        }
    },
    translationValidation: {
        model: {
            slug: {
                required,
                slug: tools.models.validators.SLUG
            },
            publish_state: { required },
            pub_at: {
                validDate: tools.models.validators.PUBLISH_DATE
            }
        }
    },
    uploads: [
        { el: "uppyImg", target: "img", folder: "/images/%Y/%M", protect: false, maxNum: 1, types: [tools.models.uploadFileTypes.IMAGE]}
    ]
};
